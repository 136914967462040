@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  top:0;
  left:0;
  margin: 0;
  padding: 0;
  background-color: #282c34;
}

.center-content{
  background-color: #fff;
  color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 10%;
  
}

.user-content{
  max-height:350px;
  text-align:center;
  border:#aaa 1px solid;
  overflow-y: scroll; /* 縦方向のスクロールを有効化 */
  scrollbar-width: thin;
  width:100%;
  margin: 30px 3%;
  /* scrollbar-color: rgba(33, 243, 150, 0.6); */
}
.user-item{
  width:100%;
  border:#aaa 1px solid;
  padding: 5px 30px;
}

.user-inline-block{
  display:inline-block;
}